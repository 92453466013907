import React, { useState, useEffect } from 'react';
import { navigate } from "gatsby";
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import axios from "../projectAxios";
import { styled } from '@mui/system';
import GetUserPrice from "../components/AdminComponents/GetUserPrice";
import EnableDisableUser from "../components/AdminComponents/EnableDisableUser";

import {
  Card,
  CardHeader,
  Button,
  TextField,
  FormControl,
  Typography,
} from "@mui/material";


const planTypes = [
  {
    value: 0,
    label: 'Basic Plan',
  }
];

const WhiteTextTypography = styled(Typography)({
  root: {
    color: "#AFE1AF"
  }
});

const UsersManagment = () => {
    const dispatch = useDispatch();
    const { isLoggedIn, token } = useSelector((state) => {
        return {
        isLoggedIn: state?.isLoggedIn || false,
        token: state?.token || null,
        };
    });

    useEffect(() => {
      if (isLoggedIn === false || token === null || token === undefined)
      {
        navigate("/login");
        return;
      }
      
    }, [isLoggedIn,  dispatch,  token]);

    const [submitMasage, setSubmitMasage] = useState(null);
    const [successMassage, setSuccessMassage] = useState(null);
    const [emailError, setEmailError] = useState(false);
    const [formError, setFormError] = useState(false);
    const [user, setUser] = useState({
      userName: "",
      password: "",
      firstName: "",
      lastName: "",
      email: "",
      plan: 0, 
    });



    


    const handleChange = (event) => {
      const { name, value } = event.target;

      if (name === "email") {
        // Validate the email format
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isEmailValid = emailPattern.test(value);
        if (value === "")
        {
          setEmailError(false);
        }
        else{
          setEmailError(!isEmailValid);
        }
      }

      setUser({
        ...user,
        [name]: value,
      });
    };

    const validateForm = () => {
      // Check if any field is empty
      for (const key in user) {
        if (user[key] === "") {
          setFormError(true);
          return false;
        }
      }
      // Check if the email format is valid
      if (emailError) {
        setFormError(true);
        return false;
      }
      setFormError(false);
      return true;
    };

    const handleCreateUser = () => {
      setSuccessMassage(null);
      setSubmitMasage(null);

      if (!validateForm()) {
        setSubmitMasage("Not a valid form");
        // Form validation failed, don't proceed with user creation
        return;
      }

      const formData = new FormData();
      for (const key in user) {
        formData.append(key, user[key]);
      }

      axios
      .post("/Auth/UserCreation", formData, { headers: {Authenticate: token, usercreation: "v66807268"}})
      .then(resp => {
        console.log("successfully added a new user");
        setUser({
          userName: "",
          password: "",
          firstName: "",
          lastName: "",
          email: "",
          plan: 0, 
        });
        setSuccessMassage("Succesfully created a new user");
      })
      .catch(err => {
        setSubmitMasage("Error in sending the form: " + err.response + " status:" + err.response.status);
      });

      
    };



    
    const createNewUserCard = (<Card>
                                <CardHeader title={"Create New User"} style={{color:"#757575"}}/>
                                <FormControl style={{width:"100%"}}> 
                                  <form>
                                    <Stack spacing={2} margin={5}>
                                      <TextField
                                        name="userName"
                                        label="User Name"
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                      <TextField
                                        name="password"
                                        label="Password"
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                      <TextField
                                        name="firstName"
                                        label="First Name"
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                      <TextField
                                        name="lastName"
                                        label="Last Name"
                                        variant="outlined"
                                        onChange={handleChange}
                                      />
                                      <TextField
                                        name="email"
                                        label="Email"
                                        type="email"
                                        variant="outlined"
                                        error={emailError}
                                        helperText={emailError ? "Invalid email format" : null}
                                        onChange={handleChange}
                                      />
                                      <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Select Plan Type"
                                        value={user.plan}
                                        onChange={handleChange}
                                      >
                                        {planTypes.map((option) => (
                                          <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCreateUser}
                                        fullWidth
                                      >
                                        Create User
                                      </Button>
                                      {formError && (
                                        <Typography color="error" align="center">
                                          Please fill out all fields correctly.
                                        </Typography>
                                      )}
                                      {submitMasage && (
                                        <Typography color="error" align="center">
                                          {submitMasage}
                                        </Typography>
                                      )}
                                      {successMassage && (
                                        <WhiteTextTypography align="center">
                                          {successMassage}
                                        </WhiteTextTypography>
                                      )}
                                    </Stack>
                                  </form>
                                </FormControl>
                              </Card>);

    return (
        <>
          <Helmet>
              <title>Metrics</title>
          </Helmet>
    
          <Layout userName={"SomeAdmin"} canReadServerMetrics={true}>

          <Stack spacing={2}>
             {createNewUserCard}

             <GetUserPrice token={token}/>

             <EnableDisableUser token={token}/>
          </Stack>


          

          {/* Add the ability to create a new user from my dashboard */}

          {/* In the future - show here users information (list of users, their payment due date, ) */}



          </Layout>
        </>
      )
}

export default UsersManagment;
