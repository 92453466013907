import React, { useState } from 'react';
import axios from "../../projectAxios";
import {
    Card,
    CardHeader,
    Button,
    TextField,
    FormControl,
    Typography,
    Stack,
    Switch,
    FormControlLabel
} from "@mui/material";

const EnableDisableUser = ({ token }) => {
    const [submitMessage, setSubmitMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [formError, setFormError] = useState(false);

    const [userEnableDisable, setUserEnableDisable] = useState({
        userName: "",
        uuid: "",
        isActive: true,
    });

    const handleChangeOfUserEnableDisable = (event) => {
        const { name, value, checked } = event.target;
        setUserEnableDisable({
            ...userEnableDisable,
            [name]: name === "isActive" ? checked : value,
        });
    };

    const validateForm = () => {
        for (const key in userEnableDisable) {
            if (userEnableDisable[key] === "" && key !== "isActive") {
                setFormError(true);
                return false;
            }
        }
        setFormError(false);
        return true;
    };

    const handleEnableDisableUser = () => {
        setSuccessMessage(null);
        setSubmitMessage(null);

        if (!validateForm()) {
            setSubmitMessage("Please fill out all fields.");
            return;
        }

        const params = new URLSearchParams({
            userName: userEnableDisable.userName,
            uuid: userEnableDisable.uuid
        });

        const data = new URLSearchParams({
            fieldName: "isActive",
            fieldValue: userEnableDisable.isActive.toString()
        });

        axios
            .post(`/Auth/UpdateUserField?${params}`, data, {
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authenticate: token 
                }
            })
            .then(resp => {
                setSubmitMessage("");
                setSuccessMessage(`User ${userEnableDisable.isActive ? 'enabled' : 'disabled'} successfully`);
            })
            .catch(err => {
                setSuccessMessage("");
                setSubmitMessage(err.response?.status
                    ? `Error in updating user status: ${err.response.status}`
                    : "An error occurred while updating user status.");
            });
    };

    return (
        <Card>
            <CardHeader title={"Enable/Disable User"} style={{ color: "#757575" }} />
            <FormControl style={{ width: "100%" }}>
                <form>
                    <Stack spacing={2} margin={5}>
                        <TextField
                            name="userName"
                            label="User Name"
                            variant="outlined"
                            onChange={handleChangeOfUserEnableDisable}
                        />
                        <TextField
                            name="uuid"
                            label="UUID"
                            variant="outlined"
                            onChange={handleChangeOfUserEnableDisable}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={userEnableDisable.isActive}
                                    onChange={handleChangeOfUserEnableDisable}
                                    name="isActive"
                                    color="primary"
                                />
                            }
                            label={userEnableDisable.isActive ? "Enable User" : "Disable User"}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleEnableDisableUser}
                            fullWidth
                        >
                            Update User Status
                        </Button>

                        {formError && (
                            <Typography color="error" align="center">
                                Please fill out all fields correctly.
                            </Typography>
                        )}
                        {submitMessage && (
                            <Typography color="error" align="center">
                                {submitMessage}
                            </Typography>
                        )}
                        {successMessage && (
                            <Typography color="success" align="center">
                                {successMessage}
                            </Typography>
                        )}
                    </Stack>
                </form>
            </FormControl>
        </Card>
    );
}

export default EnableDisableUser;